<template>
    <div class="modal-title">Подключить лицевой счет</div>
    <form class="modal-form">
        <input-text
            label       = "Номер лицевого счета"
            required    = "*"
            placeholder = "Номер ЛС"
            name        = "client-number"
            :errorText  = "addAccountError.clientNumber"
            disabled    = "inputDisabled"
            @onComplete = "getValue"
            inputHint   = "Номер лицевого счетв Вы можете посмотреть в счете на оплату."
            
        />
        <select-list
            label       = "Статус отношения к ЛС"
            :list       = "userType"
            :errorText  = "addAccountError.clientType"
            placeholder = "Выбрать"
            @listChange = "listChange"
        />
        <input-checkbox
            class       = "white"
            text        = "Я согласен получать счета по e-mail и отказываюсь<br> от получения бумажных счетов."
            name        = "email_bill_flag"
            errorText   = "Установите флажок"
            ref         = "checkboxInput"
            @onComplete = "getValue"
        />
        <div class="modal-button flex">
            <orange-button
                text            = "Подключить"
                @click.prevent  = "addAccount"
                ref             = "submitButton"
            />
            <button class="button reset" @click.prevent="$emit('showModal')">Отмена</button>
        </div>
    </form>
</template>

<script>
import axios from "axios"

export default {
    name: 'modal-add-invoice',
    emits: ['showModal'],
    computed: {
        accounts() {
            return this.$store.state.accounts
        }
    },
    data() {
        return {
            clientNumber: '',
            clientType: '',
            email_bill_flag: false,
            apiUrl: this.$store.state.apiUrl,
            token: this.$store.state.token,
            addAccountError: {
                clientNumber: '',
                clientType: ''
            },
            userType: [
                'Собственник',
                'Арендатор',
                'Зарегистрированный',
                'Проживает',
                'Другое',
            ]
        }
    },
    methods: {
        async addAccount() {
            let existsAcc = false
            
            this.addAccountError.clientNumber = false

            if (this.accounts.length) {
                existsAcc =  this.accounts.filter(val => val.client_number == this.clientNumber)
            }
                
            if (existsAcc && existsAcc.length)
                this.addAccountError.clientNumber = 'Этот лицевой счет уже подключен'
            else {
                this.$refs.checkboxInput.hideError()
                try {
                    let data = {
                        client_number: this.clientNumber,
                        relation_type: this.clientType,
                        post_bill_flag: true,
                        email_bill_flag: false
                    }

                    if (this.email_bill_flag) {
                        data.post_bill_flag = false
                        data.email_bill_flag = true
                    }

                    const response = await axios.post(
                        this.apiUrl + '/api/user/accounts',
                        data,
                        {
                            headers: {
                                'Content-Type': 'application/json',
                                'Authorization': 'Bearer ' + this.token
                            }
                        }
                    )
                    this.$emit('showModal')
                    
                    this.$store.commit('getAccounts')
                    this.addAccountError.clientNumber = ''
                    this.addAccountError.clientType = ''
                } catch (e) {
                    if (e.response.data.errors.client_number)
                        this.addAccountError.clientNumber = e.response.data.errors.client_number[0]
                    else
                        this.addAccountError.clientNumber = ''

                    if (e.response.data.errors.relation_type)
                        this.addAccountError.clientType = e.response.data.errors.relation_type[0]
                    else
                        this.addAccountError.clientType = ''

                    console.log(e.response)
                }
            }
        },
        listChange(e) {
            this.clientType = e.value
        },
        getValue(e) {
            if (e.name == 'client-number') {
                this.clientNumber = e.value
            }
            if (e.name == 'email_bill_flag') {
                this.email_bill_flag = e.checked
            }
        },
        setInputDisabled(status) {
            if (this.$refs.submitButton)
                this.$refs.submitButton.setDisabled(status)
        },
    }
}
</script>