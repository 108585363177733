<template>
  <div v-if="showBanner"
       style="
            position: fixed;
            width: 100%;
            height: 100%;
            z-index: 100;
            display: flex;
            justify-content: center;
            align-items: center;
        "
       @click="closeBanner"
  >
    <div
        style="
                background: black;
                opacity: 0.3;
                display: flex;
                width: 100%;
                height: 100%;
                position: absolute;
                z-index: -1;
            "
    ></div>

    <img
        src="/assets/images/banner.jpg"
        style="max-width: 75%; max-height: 75%"
    />

  </div>
  <div class="container flex">
    <side-bar />
    <div class="content">
      <header-block />
      <div class="content-wrap">
        <user-menu />
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    if (!this.$store.state.authStatus) this.$router.push("/auth/");
  },
  data() {
    return {
      showBanner: true,
    };
  },
  methods: {
    closeBanner() {
      this.showBanner = false;
    },
  },
};
</script>
