<template>
    <div
        class="user-bar flex"
        :class="{active: showMenu, hidden: hiddenBar}"
    >
        <a @click.prevent="showAside" href="#" class="show-aside">Ваши счета</a>
        <!-- notice -->
        <div class="user-notice flex">
            <a @click.prevent="showModalNotice" href="#" class="user-notice__trigger">
                <span class="notice-count">
                    <i v-if="notices">{{notices}}</i>
                </span>
            </a>
        </div>
        <!-- trigger -->
        <div
            class="user-trigger"
            @click="showMenu = !showMenu"
        >
            <p>{{userName}}</p>
            <span>Открыть профиль</span>
            <div class="user-trigger__pointer"></div>
        </div>
        <transition name="slidedown">
            <div v-if="showMenu" class="user-bar__menu">
                <router-link to="/profile/">Открыть профиль</router-link>
                <a href="#" @click="logout()">Выйти</a>
            </div>
        </transition>
    </div>
    <modal-win
        type="modalNotice"
        modalClass="notification-modal"
        ref="modal"
    />
</template>

<script>
export default {
    name: 'user-bar',
    props: ['hiddenBar'],
    data() {
        return {
            showMenu: false
        }
    },
    mounted() {
        if (!this.$store.state.notice)
            this.$store.commit('getNotice')
    },
    computed: {
        userName() {
            return this.$store.state.dataUser.name ? this.$store.state.dataUser.name.trim() : 'Без имени'
        },
        notices() {
            let notices = this.$store.state.notice
            let unRead = 0

            if (notices) {
                notices.forEach(item => {
                    if (!item.is_read)
                        unRead++
                })
            }

            return unRead
        }
    },
    methods: {
        logout() {
            this.$store.commit('logout')
            location.reload()
        },
        showModalNotice() {
            this.$refs.modal.showModal()
        },
        showAside() {
            let aside = document.getElementsByClassName('aside')[0]
            let asideOverlay = document.getElementsByClassName('aside-overlay')[0]

            if (!aside.classList.contains('active')) {
                asideOverlay.style.display = 'block'
                aside.classList.add('active')
            }
        }
    }
}
</script>

<style scoped>
.slidedown-enter-active {
  transition: max-height 0.5s ease;
}

.slidedown-leave-active{
    transition: max-height 0.1s ease;
}

.slidedown-enter-to,
.slidedown-leave-from {
  overflow: hidden;
  max-height: 1000px;
}

.slidedown-enter-from,
.slidedown-leave-to {
  overflow: hidden;
  max-height: 0;
}
</style>