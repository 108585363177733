<template>
    <aside class="aside">
        <a @click.prevent="hideAside" href="#" class="aside-close">
            <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11 8.5L17 2.5C17.5523 1.94772 17.5523 1.05228 17 0.5C16.4477 -0.0522847 15.5523 -0.0522853 15 0.5L9 6.5L3 0.5C2.44772 -0.0522845 1.55229 -0.0522848 1 0.5C0.447716 1.05229 0.447715 1.94771 1 2.5L7 8.5L0.999999 14.5C0.447715 15.0523 0.447715 15.9477 1 16.5C1.55228 17.0523 2.44771 17.0523 3 16.5L9 10.5L15 16.5C15.5523 17.0523 16.4477 17.0523 17 16.5C17.5523 15.9477 17.5523 15.0523 17 14.5L11 8.5Z"></path>
            </svg>                            
        </a>
        <div class="aside-scroll">
            <div class="title__col flex">
                <div class="title">Ваши счета</div>
                <a @click.prevent="showModalAddInvoice()" href="#" class="add-button">Добавить</a>
            </div>
            <user-bar-accounts />
        </div>
    </aside>
    <div class="aside-overlay"></div>
    <modal-win
        :type="modalType"
        ref="modal"
    />
</template>

<script>
export default {
    name: 'side-bar',
    data() {
        return {
            modalType: 'addInvoice'
        }
    },
    methods: {
        showModalAddInvoice() {
            this.$refs.modal.showModal()
            this.modalType = 'addInvoice'
        },
        hideAside() {
            let aside = document.getElementsByClassName('aside')[0]
            let asideOverlay = document.getElementsByClassName('aside-overlay')[0]

            if (aside.classList.contains('active')) {
                asideOverlay.style.display = 'none'
                aside.classList.remove('active')
            }
        }
    }
}
</script>