<template>
    <modal-win
        :type="modalType"
        ref="modal"
    />
    <div v-if="dataUser" class="user-data-wrap">
        <div class="title">Контакты</div>
        
        <!-- phone -->
        <ul class="user-data flex">
            <li class="user-data__type">Мобильный телефон</li>
            <li>
                <input
                    type="text"
                    readonly
                    :value="dataUser.phone"
                    v-imask="phone"
                >
            </li>
            <li class="user-data__edit">
                <a href="#" @click.prevent="showModalEditPhone" class="table-edit"></a>
            </li>
        </ul>
        <ul v-if="dataUser.extra_phone" class="user-data flex">
            <li class="user-data__type">Дополнительный номер</li>
            <li>
                <input
                    type="text"
                    readonly
                    :value="dataUser.extra_phone"
                    v-imask="phone"
                >
            </li>
            <li class="user-data__edit">
                <a href="#" @click.prevent="showModalAdditionalPhone" class="table-edit"></a>
            </li>
        </ul>
        <!-- snils -->
        <ul class="user-data flex">
            <li class="user-data__type">СНИЛС</li>
            <li>{{dataUser.snils ? 'Указан' : 'Нет'}}</li>
            <li class="user-data__edit">
                <a href="#" @click.prevent="showModalEditSnils" class="table-edit"></a>
            </li>
        </ul>
        <!-- email -->
        <ul class="user-data flex">
            <li class="user-data__type">E-mail</li>
            <li>{{dataUser.email ? dataUser.email : 'Нет'}}</li>
            <li class="user-data__edit">
                <a href="#" @click.prevent="showModalEditEmail" class="table-edit"></a>
            </li>
        </ul>
        <ul v-if="dataUser.extra_email" class="user-data flex">
            <li class="user-data__type">Дополнительный E-mail</li>
            <li>{{dataUser.extra_email}}</li>
            <li class="user-data__edit">
                <a href="#" @click.prevent="showModalAdditionalEmail" class="table-edit"></a>
            </li>
        </ul>
        
        <!-- password -->
        <ul class="user-data flex">
            <li class="user-data__type">Пароль</li>
            <li>•••••••••••••</li>
            <li class="user-data__edit"><a href="#" @click.prevent="showModalChangePass" class="table-edit"></a></li>
        </ul>
        <!-- butttons -->
        <div class="user-data__buttons">
            <button v-if="!dataUser.extra_phone" @click.prevent="showModalAdditionalPhone" class="button reset">Дополнительный номер</button>
            <button v-if="!dataUser.extra_email" @click.prevent="showModalAdditionalEmail" class="button reset">Дополнительный E-mail</button>
        </div>
    </div>
</template>

<script>
import { IMaskDirective } from 'vue-imask'

export default {
    name: 'user-contact',
    data() {
        return {
            token: this.$store.state.token,
            apiUrl: this.$store.state.apiUrl,
            phone: {
                mask: '+{7} (000) 000-00-00'
            },
            modalType: ''
        }
    },
    directives: {
        imask: IMaskDirective
    },
    mounted() {
        this.$store.commit('getUserInfo')
    },
    computed: {
        dataUser() {
            return this.$store.state.dataUser
        }
    },
    methods: {
        showModalEditPhone() {
            this.$refs.modal.showModal()
            this.modalType = 'editPhone'
        },
        showModalEditSnils() {
            this.$refs.modal.showModal()
            this.modalType = 'editSnils'
        },
        showModalEditEmail() {
            this.$refs.modal.showModal()
            this.modalType = 'editEmail'
        },
        showModalChangePass() {
            this.$refs.modal.showModal()
            this.modalType = 'changePass'
        },
        showModalAdditionalEmail() {
            this.$refs.modal.showModal()
            this.modalType = 'additionEmail'
        },
        showModalAdditionalPhone() {
            this.$refs.modal.showModal()
            this.modalType = 'additionPhone'
        }
    }
}
</script>