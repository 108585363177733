<template>
    <div @mouseover="show = true" @mouseout="show = false" class="input-hint__show" :class="class">
        <span v-if="show" class="modal-hint">{{text}}</span>
    </div>
</template>

<script>
export default {
    name: 'popup-hint',
    props: ['text', 'class'],
    data() {
        return {
            show: false
        }
    }
}
</script>