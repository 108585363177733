<template>
    <div class="title">Уведомления</div>
    <div v-if="notices" v-for="(notice, key) in notices" class="notifications-list">
        <div class="notifications-date">{{key}}</div>
        <!-- item -->
        <div v-for="item in notice" class="notifications-item">
            <div class="notifications-title flex">
                <span
                    :class="{
                        'status-attention': item.category_name == 'warning',
                        'status-ok': item.category_name == 'success',
                    }"
                >{{item.title}}</span>
                <span class="notifications-time">{{item.created_at_time}}</span>
            </div>
            <div class="notifications-body" v-html="item.text"></div>
            <a
                v-if="!item.is_read"
                @click.prevent="noticeRead(item.id)"
                href="#" class="small-button"
                :class="{
                    'red-button': item.category_name == 'warning',
                }"
            >Прочитать</a>
            <span
                v-else
                class="small-button"
                :class="{
                    'red-button': item.category_name == 'warning',
                }"
            >Прочитано</span>
        </div>
    </div>
    <div v-else>Нет уведомлений</div>
</template>

<script>
import axios from 'axios'
import moment from 'moment'

export default {
    name: 'user-notice',
    data() {
        return {
            apiUrl: this.$store.state.apiUrl,
            token: this.$store.state.token,
        }
    },
    mounted() {
        
    },
    computed: {
        notices() {
            let notices = this.$store.state.notice
            let sortNotices = {}

            if (notices) {
                notices.sort((a, b) => {return b.created_at - a.created_at})

                notices.forEach((item) => {
                    if (!Array.isArray(sortNotices[item.created_at]))
                        sortNotices[item.created_at] = []
                    
                    sortNotices[item.created_at].push(item)
                })
            }

            if (Object.keys(sortNotices).length == 0)
                return false
            else {
                return sortNotices
            }
        }
    },
    methods: {
        async noticeRead(id) {
            try {
                let data = {
                    id,
                    is_read: true
                }

                const response = await axios.patch(
                    this.apiUrl + '/api/notifications',
                    data,
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + this.token
                        }
                    }
                )
                
                this.$store.commit('getNotice')

            } catch (e) {
                console.log(e);
            }
        }
    }
}
</script>