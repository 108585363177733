<template>
    <div v-if="accounts" class="user-data-wrap">
        <div class="title">Получение счета по e-mail</div>
        <p class="user-data__hint">Откажитесь от печатной квитанции в пользу ее электронной версии.<br> Выберите лицевые счета, по которым вы хотите получать электронную корреспонденцию.</p>
        <div class="table white">
            <ul class="thead">
                <li>E-mail</li>
                <li>Бумажный</li>
                <li></li>
                <li>E-mail для квитанций</li>
                <li></li>
                <li>Адрес для квитанций</li>
                <li></li>
            </ul>
            <ul v-for="(item, i) in accounts" :key="i" class="tbody" :style="{top: i * 4 + 'px'}">
                <li>
                    <label class="radio" :class="{blue: item.cis_division == 'GESK'}">
                        <input
                            v-if="item.email_bill_flag"
                            type="radio"
                            :name="'type_' + item.client_number"
                            checked
                            value="email"
                            @change="setBillMethod"
                            :data-client-number="item.client_number"
                        >
                        <input
                            v-else
                            type="radio"
                            :name="'type_' + item.client_number"
                            value="email"
                            @change="setBillMethod"
                            :data-client-number="item.client_number"
                        >
                        <span></span>
                    </label>
                </li>
                <li>
                    <label class="radio" :class="{blue: item.cis_division == 'GESK'}">
                        <input
                            v-if="item.post_bill_flag"
                            type="radio"
                            :name="'type_' + item.client_number"
                            checked
                            value="post"
                            @change="setBillMethod"
                            :data-client-number="item.client_number"
                        >
                        <input
                            v-else
                            type="radio"
                            :name="'type_' + item.client_number"
                            value="post"
                            @change="setBillMethod"
                            :data-client-number="item.client_number"
                        >
                        <span></span>
                    </label>
                </li>
                <li class="invoice-number-table">
                    <div class="flex">
                        <img
                            :src="item.cis_division == 'GESK' ? '/assets/images/logo-noviten-40x7.png' : '/assets/images/logo-lesk-40x14.png'"
                            alt=""
                        >
                        <input-text
                            :inputValue="item.client_number"
                            maskVal="invoice"
                        />
                    </div>
                </li>
                <li>
                    {{item.email_bill ? item.email_bill : 'Не установлен'}}
                </li>
                <li>
                    <a @click.prevent="showModalEditEmail" :data-client-number="item.client_number" :data-email-value="item.email_bill" href="#" class="table-edit"></a>
                </li>
                <li>
                    {{item.bill_address ? item.bill_address : 'Не установлен'}}
                </li>
                <li>
                    <a @click.prevent="showModalEditAddress" :data-client-number="item.client_number" :data-address-value="item.bill_address" href="#" class="table-edit"></a>
                </li>
            </ul>
        </div>
        <div v-for="item in accounts" class="receiving-receipts">
            <div class="table-mob white">
                <div class="table-mob__item flex">
                    <img
                        :src="item.cis_division == 'GESK' ? '/assets/images/logo-noviten-40x7.png' : '/assets/images/logo-lesk-40x14.png'"
                        alt=""
                    >
                    <input-text
                        :inputValue="item.client_number"
                        maskVal="invoice"
                    />
                </div>
                <div class="table-mob__item">
                    <p>E-mail для квитанций</p>
                    <span>{{item.email_bill ? item.email_bill : 'Не установлен'}}</span>
                    <a @click.prevent="showModalEditEmail" :data-client-number="item.client_number" :data-email-value="item.email_bill" href="#" class="edit-min"></a>
                </div>
                <div class="table-mob__item">
                    <p>Адрес для квитанций</p>
                    <span>{{item.bill_address ? item.bill_address : 'Не установлен'}}</span>
                    <a @click.prevent="showModalEditAddress" href="#" class="edit-min"></a>
                </div>
                <div class="table-mob__radio flex">
                    <label class="radio" :class="{blue: item.cis_division == 'GESK'}">
                        <input
                            v-if="item.email_bill_flag"
                            type="radio"
                            :name="'mob_type_' + item.client_number"
                            checked
                            value="email"
                            @change="setBillMethod"
                            :data-client-number="item.client_number"
                        >
                        <input
                            v-else
                            type="radio"
                            :name="'mob_type_' + item.client_number"
                            value="email"
                            @change="setBillMethod"
                            :data-client-number="item.client_number"
                        >
                        <span></span>
                        <i>E-mail</i>
                    </label>
                    <label class="radio" :class="{blue: item.cis_division == 'GESK'}">
                        <input
                            v-if="item.post_bill_flag"
                            type="radio"
                            :name="'mob_type_' + item.client_number"
                            checked
                            value="post"
                            @change="setBillMethod"
                            :data-client-number="item.client_number"
                        >
                        <input
                            v-else
                            type="radio"
                            :name="'mob_type_' + item.client_number"
                            value="post"
                            @change="setBillMethod"
                            :data-client-number="item.client_number"
                        >
                        <span></span>
                        <i>Бумажный</i>
                    </label>
                </div>
            </div>
        </div>
        <button v-if="bindingsCard && !currentAccount.autopay && currentAccount.customer_class != 'QL'" @click.prevent="showModalAutoPay" class="button">Подключить автоплатеж</button>
        <button v-if="currentAccount.autopay" class="button reset">Отключить</button>
    </div>
    <modal-win type="autoPay" ref="modalAutoPay" modalClass="wide-modal" />
    <modal-win
        :type="modalType"
        :dataClientNumber="selectClientNumber"
        :dataEmailValue="emailBill"
        :dataAddressValue="addressBill"
        :inputName="inputName"
        @setEmail="setEmail"
        ref="modal"
    />
</template>

<script>
import axios from "axios"

export default {
    name: 'user-receiving-ivoice',
    data() {
        return {
            apiUrl: this.$store.state.apiUrl,
            token: this.$store.state.token,
            modalType: '',
            inputName: '',
            selectClientNumber: '',
            emailBill: '',
            addressBill: '',
            changeRadio: false,
            account: false
        }
    },
    computed: {
        userInfo() {
            return this.$store.state.dataUser
        },
        accounts() {
            return this.$store.state.accounts
        },
        currentAccount() {
            return this.$store.state.currentAccount
        },
        bindingsCard() {
            return this.$store.state.bindingsCard
        }
    },
    methods: {
        setEmail(e) {
            if (e.set) {
                this.account[0].email_bill = e.email
                this.setBillMethod(this.changeRadio)
            }
        },
        showModalEditEmail(e) {
            this.$refs.modal.showModal()
            this.modalType = 'editEmailBill'
            this.selectClientNumber = e.target.attributes['data-client-number'].value
            this.emailBill = e.target.attributes['data-email-value'] ? e.target.attributes['data-email-value'].value : ''
        },
        showModalEditAddress(e) {
            this.$refs.modal.showModal()
            this.modalType = 'editAddressBill'
            this.selectClientNumber = e.target.attributes['data-client-number'].value
            this.addressBill = e.target.attributes['data-address-value'] ? e.target.attributes['data-address-value'].value : ''
        },
        async setBillMethod(e) {
            try {
                let clientNumber = e.target.attributes['data-client-number'].value
                let send = false
                let data = {
                    accounts: [
                        {
                            client_number: clientNumber
                        }
                    ]
                }

                let value = e.target.value

                if (value == 'email') {
                    this.account = this.accounts.filter(account => account.client_number == clientNumber)
                    
                    if (!this.account[0].email_bill) {
                        this.changeRadio = e
                        this.selectClientNumber = clientNumber
                        this.modalType = 'editEmailBill'
                        this.inputName = e.target.name
                        this.emailBill = this.userInfo.email ? this.userInfo.email : this.userInfo.extra_email

                        this.$refs.modal.showModal()
                    } else {
                        send = true
                        data.accounts[0].email_bill_flag = true
                        data.accounts[0].post_bill_flag = false
                    }

                } else {
                    send = true
                    data.accounts[0].email_bill_flag = false
                    data.accounts[0].post_bill_flag = true
                }

                if (send) {
                    const response = await axios.patch(
                        this.apiUrl + '/api/user/accounts',
                        data,
                        {
                            headers: {
                                'Content-Type': 'application/json',
                                'Authorization': 'Bearer ' + this.token
                            }
                        }
                    )
                    // console.log(response)
                }
            } catch (e) {
                console.log(e)
            }
        },
        showModalAutoPay() {
            this.$refs.modalAutoPay.showModal()
        }
    }
}
</script>