<template>
    <button
        class="button"
        :disabled="inputDisabled"
        :class="classSave"
    >{{text}}</button>
</template>

<script>
export default {
    name: 'orange-button',
    props: ['text', 'classSave'],
    data() {
        return {
            inputDisabled: false
        }
    },
    methods: {
        setDisabled(status) {
            this.inputDisabled = status
        }
    },
}
</script>