import AuthForm                 from '@/components/AuthReg/AuthForm'
import RegForm                  from '@/components/AuthReg/RegForm'
import InputText                from '@/components/UI/InputText'
import InputPassword            from '@/components/UI/InputPassword'
import OrangeButton             from '@/components/UI/OrangeButton'
import InputCheckbox            from '@/components/UI/InputCheckbox'
import SelectList               from '@/components/UI/SelectList'
import FooterBlock              from '@/components/FooterBlock'
import SideBar                  from '@/components/SideBar'
import HeaderBlock              from '@/components/HeaderBlock'
import HeaderBlockProfile       from '@/components/HeaderBlockProfile'
import HeaderBlockSmall         from '@/components/HeaderBlockSmall'
import UserBar                  from '@/components/User/UserBar'
import UserBarAccounts          from '@/components/User/UserBarAccounts'
import UserContact              from '@/components/User/UserContact'
import UserReceivingInvoice     from '@/components/User/UserReceivingInvoice'
import UserMenu                 from '@/components/User/UserMenu'
import UserPayments             from '@/components/User/UserPayments'
import UserMetersData           from '@/components/User/UserMetersData'
import UserMetersTable          from '@/components/User/UserMetersTable'
import UserNotice               from '@/components/User/UserNotice'
import UserBills                from '@/components/User/UserBills'
import UserBillItem             from '@/components/User/UserBillItem'
import UserDocs                 from '@/components/User/UserDocs'
import UserFeedback             from '@/components/User/UserFeedback'
import ModalWin                 from '@/components/Modal/ModalWin'
import ModalForgotPassword      from '@/components/Modal/ModalForgotPassword'
import ModalEditPersonalData    from '@/components/Modal/ModalEditPersonalData'
import ModalEditPhone           from '@/components/Modal/ModalEditPhone'
import ModalEditEmail           from '@/components/Modal/ModalEditEmail'
import ModalEditSnils           from '@/components/Modal/ModalEditSnils'
import ModalEditEmailBill       from '@/components/Modal/ModalEditEmailBill'
import ModalEditAddressBill     from '@/components/Modal/ModalEditAddressBill'
import ModalAddInvoice          from '@/components/Modal/ModalAddInvoice'
import ModalDeleteInvoice       from '@/components/Modal/ModalDeleteInvoice'
import ModalAutoPay             from '@/components/Modal/ModalAutoPay'
import ModalChangePassword      from '@/components/Modal/ModalChangePassword'
import ModalFeedback            from '@/components/Modal/ModalFeedback'
import ModalNotice              from '@/components/Modal/ModalNotice'
import ModalSuccess             from '@/components/Modal/ModalSuccess'
import ModalAdditionalEmail     from '@/components/Modal/ModalAdditionalEmail'
import ModalAdditionalPhone     from '@/components/Modal/ModalAdditionalPhone'
import HistoryPage              from '@/components/HistoryPage'
import PopupHint                from '@/components/UI/PopupHint'
import PreloaderData            from '@/components/PreloaderData'
import { IMaskDirective }       from 'vue-imask'
import LitepieDatepicker        from 'litepie-datepicker'

export default [
    AuthForm,
    RegForm,
    InputText,
    InputPassword,
    OrangeButton,
    InputCheckbox,
    SelectList,
    IMaskDirective,
    FooterBlock,
    SideBar,
    HeaderBlock,
    HeaderBlockProfile,
    HeaderBlockSmall,
    UserBar,
    UserBarAccounts,
    UserContact,
    UserReceivingInvoice,
    UserMenu,
    UserPayments,
    UserMetersData,
    UserMetersTable,
    UserNotice,
    UserBills,
    UserBillItem,
    UserDocs,
    UserFeedback,
    ModalWin,
    ModalForgotPassword,
    ModalEditPhone,
    ModalEditEmail,
    ModalEditSnils,
    ModalEditEmailBill,
    ModalEditAddressBill,
    ModalEditPersonalData,
    ModalAddInvoice,
    ModalDeleteInvoice,
    ModalAutoPay,
    ModalChangePassword,
    ModalFeedback,
    ModalNotice,
    ModalSuccess,
    ModalAdditionalEmail,
    ModalAdditionalPhone,
    LitepieDatepicker,
    PreloaderData,
    HistoryPage,
    PopupHint,
]