<template>
    <div v-if="!id">
        <div class="title">Обращения</div>
        <p class="page-hint">Срок ответа на обращение, поступившие в электронной форме, составляет от 1 до 30 дней, со дня регистрации в соответствии с ч. 1 ст. 12 Федерального закона от 02.05.2006 № 59-ФЗ «О порядке рассмотрения обращений граждан Российской Федерации». Обращение, содержащее нецензурные, либо оскорбительные выражения, останется без рассмотрения.</p>
        <a @click.prevent="showModalFeedback" href="#" class="button">Новое обращение</a>
        <div class="feedback-list">
            <div v-for="item in feedback" class="feedback-item">
                <div class="feedback-head flex">
                    <span>Обращение № {{item.id}}</span>
                    <span>{{item.created_at}}</span>
                </div>
                <div class="feedback-title">
                    <router-link :to="'/feedback?id=' + item.id">{{item.text}}</router-link>
                </div>
                <span class="small-button green-button">Принято</span>
            </div>
        </div>
        <modal-win
            type="modalFeedback"
            ref="modal"
            modalClass="new-appeal"
        />
    </div>
    <div v-else-if="feedbackDetail">
        <a href="#" @click.prevent="backPage()" class="backward-button large back-list-page">
            <i>
                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11 6.8C11.4418 6.8 11.8 6.44183 11.8 6C11.8 5.55817 11.4418 5.2 11 5.2V6.8ZM0.434315 5.43431C0.121895 5.74673 0.121895 6.25327 0.434315 6.56569L5.52548 11.6569C5.8379 11.9693 6.34443 11.9693 6.65685 11.6569C6.96927 11.3444 6.96927 10.8379 6.65685 10.5255L2.13137 6L6.65685 1.47452C6.96927 1.1621 6.96927 0.655565 6.65685 0.343146C6.34443 0.0307264 5.8379 0.0307264 5.52548 0.343146L0.434315 5.43431ZM11 5.2L1 5.2V6.8L11 6.8V5.2Z"></path>
                </svg>
            </i>
            Обращение №{{id}}
        </a>
        <ul class="feedback-param">
            <li>Раздел: <span>{{feedbackDetail.category}}</span></li>
            <li>Тема: <span>{{feedbackDetail.theme}}</span></li>
            <li>Дата: <span>{{feedbackDetail.created_at_detail}}</span></li>
        </ul>
        <span class="small-button green-button">Принято</span>
        <div class="feedback-detail">
            <div class="feedback-scroll">
                <div class="feedback-line">
                    <div class="feedback-user">
                        <p>{{feedbackDetail.text}}</p>
                        <div class="feedback-time">{{feedbackDetail.created_at_detail_time}}</div>
                    </div>
                </div>
                <div v-if="feedbackDetail.files.length" class="feedback-line">
                    <div class="feedback-user feedback-attach">
                        <a v-for="file in feedbackDetail.files" :href="apiUrl + file.file_path" target="_blank" class="feedback-attach__item">
                            <div class="feedback-attach__preview">
                                <img :src="apiUrl + file.file_path" alt="">
                            </div>
                            <div class="feedback-attach__info">
                                <p>{{file.name}}</p>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['id'],
    name: 'user-feedback',
    data() {
        return {
            apiUrl: this.$store.state.apiUrl
        }
    },
    mounted() {
        this.$store.commit('getPremisesMetersStatements')
    },
    computed: {
        feedback() {
            let feedback = this.$store.state.feedback

            if (!feedback)
                this.$store.commit('getFeedback')

            return feedback
        },
        feedbackDetail() {
            return this.$store.getters.feedback(this.id)
        },
    },
    methods: {
        showModalFeedback() {
            this.$refs.modal.showModal()
        },
        backPage() {
            this.$router.go(-1)
        }
    }
}
</script>