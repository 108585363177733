<template>
  <div class="container flex" style="background: #FF845A;">
    <div style="margin: 0 auto;">
      <h2 style="padding: 20px 0px;color: white;font-size: 17px;font-weight: bold;text-align: center;">Ведутся технические работы</h2>
      <img src="/assets/images/maintenance.jpg" alt="">
    </div>
  </div>
</template>

<script>

export default {
  name: 'maintenance-page',
  data() {
    return {
    }
  },
}
</script>