<template>
    <div v-if="!resetOk" class="reset-password">
        <div class="modal-title">Восстановление пароля</div>
        <div v-if="!forgetStep2 && !forgetStep3">
            <form class="modal-form">
                <input-text
                    label       = "Телефон или E-mail"
                    required    = "*"
                    :errorText  = "forgotErrors"
                    placeholder = "+7 (ххх) ххх-хх-хх или E-mail"
                    maskVal     = "phone-email"
                    name        = "reset-password"
                    disabled    = "inputDisabled"
                    ref         = "resetPasswordInput"
                    @onComplete = "getValue"
                    @onAccept   = "onAccept"
                />

                <p class="modal-input-hint">При вводе телефона Вам придет SMS-код.<br> При вводе E-mail Вам придет письмо со ссылкой.</p>
                <div class="modal-button flex">
                    <orange-button
                        text            = "Восстановить"
                        @click.prevent  = "forgot"
                        ref             = "submitButton"
                    />
                    <button class="button reset">Отмена</button>
                </div>
            </form>
        </div>
        <div v-else-if="forgetStep2">
            <a
                href="#"
                class="backward-button"
                @click.prevent="forgetStep2 = !forgetStep2"
            >
                <i>
                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M11 6.8C11.4418 6.8 11.8 6.44183 11.8 6C11.8 5.55817 11.4418 5.2 11 5.2V6.8ZM0.434315 5.43431C0.121895 5.74673 0.121895 6.25327 0.434315 6.56569L5.52548 11.6569C5.8379 11.9693 6.34443 11.9693 6.65685 11.6569C6.96927 11.3444 6.96927 10.8379 6.65685 10.5255L2.13137 6L6.65685 1.47452C6.96927 1.1621 6.96927 0.655565 6.65685 0.343146C6.34443 0.0307264 5.8379 0.0307264 5.52548 0.343146L0.434315 5.43431ZM11 5.2L1 5.2V6.8L11 6.8V5.2Z"></path>
                    </svg>
                </i>
                Назад
            </a>
            <form class="modal-form">
                <input-text
                    label       = "Код подтверждения"
                    required    = "*"
                    :errorText  = "verificationCodeEmpty"
                    placeholder = "Код подтверждения*"
                    name        = "reset-password"
                    disabled    = "inputDisabled"
                    ref         = "resetPasswordInput"
                    @onComplete = "getValue"
                />
                <div class="modal-button flex">
                    <orange-button
                        text            = "Восстановить"
                        @click.prevent  = "step3"
                        ref             = "submitButton"
                    />
                    <button class="button reset">Отмена</button>
                </div>
            </form>
        </div>
        <div v-else>
            <form class="modal-form">
                <input-password
                    label       = "Пароль"
                    required    = "*"
                    :errorText  = "forgotErrors"
                    placeholder = "Пароль"
                    name        = "password"
                    ref         = "passwordInput"
                    @onComplete = "getValuePassword"
                />
                <input-password
                    label       = "Подтвердите пароль"
                    required    = "*"
                    :errorText  = "forgotErrors"
                    placeholder = "Подтвердите пароль"
                    name        = "repassword"
                    ref         = "repasswordInput"
                    @onComplete = "getValueRePassword"
                />
                <p class="input-error" v-if="verificationCodeEmpty">{{verificationCodeEmpty}}</p>
                <p class="input-error" v-if="generalError">{{generalError}}</p>
                <div class="modal-button flex">
                    <orange-button
                        text            = "Сохранить"
                        @click.prevent  = "reset"
                        ref             = "submitButton"
                        classSave      = "save-pass"
                    />
                </div>
            </form>
        </div>
    </div>
    <div v-else class="modal-win__wrap">
        <div class="modal-win__inner">
            <img src="/assets/images/success.svg" alt="">
            <p>Новый пароль установлен</p>
        </div>
    </div>
</template>

<script>
import axios from "axios"

export default {
    name: 'modal-forgot-password',
    data() {
        return {
            value: '',
            maskValue: '',
            apiUrl: this.$store.state.apiUrl,
            forgotErrors: false,
            forgetStep2: false,
            forgetStep3: false,
            verificationCode: '',
            verificationCodeEmpty: '',
            generalError: '',
            forgotUserData: {},
            password: '',
            repassword: '',
            phone: false,
            resetOk: false
        }
    },
    methods: {
        onAccept(e) {
            this.phone = e.value
        },
        getValue(e) {
            if (!this.forgetStep2) {
                this.value = e.value
                this.maskValue = e.maskValue
            } else {
                this.verificationCode = e.value
            }
        },
        getValuePassword(e) {
            this.password = e.value
        },
        getValueRePassword(e) {
            this.repassword = e.value
        },
        async forgot() {
            var data = {}

            let value = Number.parseInt(this.value)
            if (value)
                data.phone = this.phone
            else
                data.email = this.value
            
            this.forgotUserData = data
            
            this.setInputDisabled(true)

            try {
                const response = await axios.post(
                    this.apiUrl + '/api/auth/forgot',
                    data,
                    {
                        headers: {
                            'Content-Type': 'application/json',
                        }
                    }
                )
                this.forgetStep2 = true
                this.forgotErrors = false
            } catch (e) {
                if (e.response.data.errors && e.response.data.errors.phone)
                    this.forgotErrors = e.response.data.errors.phone[0]
                else if (e.response.data.errors && e.response.data.errors.email)
                    this.forgotErrors = e.response.data.errors.email[0]
                else
                    this.forgotErrors = e.response.data.message

                this.setInputDisabled(false)
                console.log(e.response.data);
            }
        },
        async reset() {
            var data = this.forgotUserData
            data.verification_code      = this.verificationCode
            data.password               = this.password
            data.password_confirmation  = this.repassword
            
            this.setInputDisabled(true)

            try {
                const response = await axios.post(
                    this.apiUrl + '/api/auth/reset',
                    data,
                    {
                        headers: {
                            'Content-Type': 'application/json',
                        }
                    }
                )
                
                this.$emit('onSuccess')
                this.resetOk = true
                this.forgetStep3 = false

                this.$store.commit('login', {phone: this.value, password: this.password})

            } catch (e) {
                if (e.response.data.errors && e.response.data.errors.password)
                    this.forgotErrors = e.response.data.errors.password[0]
                else
                    this.forgotErrors = false
                if (e.response.data.errors && e.response.data.errors.verification_code)
                    this.verificationCodeEmpty = e.response.data.errors.verification_code[0]
                else
                    this.verificationCodeEmpty = false
                if (e.response.data.message)
                    this.generalError = e.response.data.message
                else
                    this.generalError = false

                this.setInputDisabled(false)
                console.log(e.response.data);
            }
        },
        setInputDisabled(status) {
            if (this.$refs.submitButton)
                this.$refs.submitButton.setDisabled(status)
        },
        step3() {
            if (this.verificationCode) {
                this.forgetStep2 = false
                this.forgetStep3 = true
            } else {
                this.verificationCodeEmpty = 'Введите проверочный код'
            }
        },
    }
}
</script>