<template>
    <div
        class="invoice"
        v-if="accounts && currentAccount"
        v-for="(item, i) in accounts"
        :class="{
            noviten: item.cis_division == 'GESK',
            active: item.client_number == currentAccount.client_number,
            indebt: item.saldo.balance.status == 'debit'
        }"
        @click.prevent="selectAccount(item.client_number)"
    >
        <div v-if="item.person" class="invoice-address">{{item.person.results.adrGroup.fullAddress}}</div>
        <div v-else class="invoice-address">Нет адреса</div>
        
        <div class="invoice-number"></div>
        <input-text
            :inputValue="item.client_number"
            class="invoice-number"
            maskVal="invoice"
        />
        <div class="invoice-notice">
            <input :value="item.note" class="notice-input" type="text" placeholder="Введите заметку">
            <div class="notice-input__buttons">
                <i class="notice-input__edit"></i>
                <div class="notice-input__actions">
                    <a href="#" class="cancel"></a>
                    <a @click.prevent="addNote" href="#" class="apply"></a>
                </div>
            </div>
        </div>
        <router-link
            @click.prevent="selectAccount(item.client_number)"
            class="invoice-edit"
            to="/account/"></router-link>
        <router-link
            v-if="item.saldo.balance.status == 'debit'"
            to="/pay/"
            class="invoice-indebt"
            @click.prevent="selectAccount(item.client_number)"
        >Задолженность {{item.saldo.balance.currentBalance}} руб.</router-link>
    </div>
    <p v-else class="empty-text">У Вас нет лицевых счетов.<br> Вы можете добавить их здесь.</p>
</template>

<script>
import axios from 'axios'

export default {
    name: 'user-bar-accounts',
    data() {
        return {
            invoiceMask: {
                mask: '000 000 00'
            },
            account_id: this.$store.state.selectAccount,
            apiUrl: this.$store.state.apiUrl,
            token: this.$store.state.token,
        }
    },
    computed: {
        accounts() {
            let accounts = this.$store.state.accounts

            if (accounts.length) {
                return accounts.sort(this.sortAccounts)
            } else
                return false
        },
        currentAccount() {
            this.$store.getters.currentAccount(this.account_id)

            // if (!this.$store.state.bindingsCard)
            //     this.$store.commit('getBindingsCard')
                
            return this.$store.state.currentAccount
        }
    },
    mounted() {
        if (!this.$store.state.accounts.length)
            this.$store.commit('getAccounts')
    },
    methods: {
        sortAccounts(a, b) {
            return a.client_number - b.client_number
        },
        selectAccount(acctountId) {
            this.account_id = acctountId
            this.$store.commit('setSelectAccount', acctountId)
            this.$store.currentStatement = false
            this.hideAside()
        },
        hideAside() {
            let aside = document.getElementsByClassName('aside')[0]
            let asideOverlay = document.getElementsByClassName('aside-overlay')[0]

            if (aside.classList.contains('active')) {
                asideOverlay.style.display = 'none'
                aside.classList.remove('active')
            }
        },
        async addNote(e) {
            let noteVal = e.target.parentNode.parentNode.parentNode.getElementsByClassName('notice-input')[0].value
            console.log(noteVal);
            try {
                const response = await axios.patch(
                    this.apiUrl + '/api/user/accounts',
                    {
                        accounts: [
                            {
                                client_number: this.account_id,
                                note: noteVal
                            }
                        ]
                    },
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + this.token
                        }
                    }
                )
                this.$store.commit('setAccountNote', {note: noteVal, client_number: this.account_id})
            } catch (e) {
                console.log(e);
            }
        }
    }
}
</script>