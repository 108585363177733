<template>
	<div v-if="item.no_bill" class="bill-item no-bill">
		<div class="bill-head flex">
			<div class="bill-trigger">
				<div class="bill-date">{{item.date}}</div>
			</div>
			<div class="bill-actions no-bill-text flex">{{item.message}}</div>
		</div>
	</div>
	<div v-else-if="currentAccount.customer_class != 'QL'" class="bill-item"
		:class="{
				'not-paid': item.unpaid
		}">
	    <div @click.prevent="show = !show" class="bill-head flex"
			:class="{
				active: show,
				'display-pdf': item.pdf && current == 0
			}">
	        <div class="bill-trigger">
	            <div class="bill-date">{{item.date}}</div>
	        </div>
	        <div v-if="item.unpaid" class="bill-actions flex">
	            <a v-if="item.pdf" @click.stop :href="item.pdf" target="_blank" class="small-button print-button">Печать квитанции</a>
	            <router-link @click.stop class="small-button fill" to="/pay/">Оплатить {{Math.abs(currentAccount.saldo.balance.currentBalance).toFixed(2)}} руб.</router-link>
	        </div>
	        <div v-else class="bill-actions flex">
				<a v-if="item.pdf && current == 0" @click.stop :href="item.pdf" target="_blank" class="small-button print-button">Печать квитанции</a>
	        	<div class="payment-state paid">Оплачено</div>
	        </div>
	    </div>
	    <transition name="slidedown">
		    <div v-if="show" class="bill-table">
		        <table>
		            <thead>
		                <tr>
		                    <th>Статья расчета</th>
		                    <th>Объем, кВтч</th>
		                    <th>Тариф, руб./кВтч</th>
		                    <th>Стоимость, руб.</th>
		                </tr>
		            </thead>
		            <tbody>
						<tr v-for="row in item.calcRows">
							<td>
								{{row.calcItem}}
							</td>
							<td>
								{{row.calcKwt}}
							</td>
							<td>
								{{row.calcTarif}}
							</td>
							<td>
								{{row.calcSum.replace('-', '')}}
							</td>
						</tr>
		            </tbody>
		        </table>
		    </div>
		</transition>
	</div>
	<div v-else class="bill-item"
		:class="{
			'not-paid': item.unpaid
		}">
		<div @click.prevent="show = !show" class="bill-head flex" :class="{active: show}">
	        <div class="bill-trigger">
	            <div class="bill-date">{{item.date}}</div>
	        </div>
			<div class="bill-actions flex"></div>
	    </div>
		<transition name="slidedown">
			<div v-if="show" class="bill-table">
				<table class="ql">
					<thead>
						<tr>
							<th>Статья расчета</th>
							<th>Дата</th>
							<th>Сумма, руб.</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>Обязательный платеж</td>
							<td>{{item.fts[0].date}}</td>
							<td>{{item.fts[0].sum}}</td>
						</tr>
						<tr>
							<td>Обязательный платеж</td>
							<td>{{item.fts[1].date}}</td>
							<td>{{item.fts[1].sum}}</td>
						</tr>
						<tr>
							<td>Сумма</td>
							<td></td>
							<td>{{item.fts[5].sum}}</td>
						</tr>
					</tbody>
				</table>
			</div>
		</transition>
	</div>
</template>

<script>
export default {
	name: 'user-bill-item',
	props: ['item', 'show', 'all', 'current'],
	data() {
		return {
			token: this.$store.state.token
		}
	},
	computed: {
		currentAccount() {
			return this.$store.state.currentAccount
		}
	}
}
</script>

<style scoped>
.slidedown-enter-active {
  transition: max-height 0.5s ease;
}

.slidedown-leave-active{
    transition: max-height 0.1s ease;
}

.slidedown-enter-to,
.slidedown-leave-from {
  overflow: hidden;
  max-height: 1000px;
}

.slidedown-enter-from,
.slidedown-leave-to {
  overflow: hidden;
  max-height: 0;
}
</style>