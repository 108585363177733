<template>
    <div class="modal-title">Удалить лицевой счет</div>
    <form class="modal-form">
        <div class="modal-account-columns flex">
            <div class="modal-account__logo">
                <img :src="currentAccount.cis_division == 'GESK' ? '/assets/images/noviten1.png' : '/assets/images/logo-lesk.png'" alt="">
            </div>
            <div class="modal-account__data">
                <p v-if="currentAccount.person.results.adrGroup.fullAddress">{{currentAccount.person.results.adrGroup.fullAddress}}</p>
                <p v-else>Нет адреса</p>
                <div class="current-invoice">
                    <span>Лицевой счет: </span>
                    <input-text
                        :inputValue="currentAccount.client_number"
                        maskVal="invoice"
                    />
                </div>
            </div>
        </div>
        <div class="modal-button flex">
            <button @click.prevent="deleteInvoice(currentAccount.client_number)" class="button">Удалить</button>
            <button @click.prevent="$emit('showModal')" class="button reset">Отмена</button>
        </div>
    </form>
</template>

<script>
import axios from "axios"

export default {
    name: 'modal-delete-invoice',
    emits: ['showModal'],
    data() {
        return {
            apiUrl: this.$store.state.apiUrl,
            token: this.$store.state.token
        }
    },
    computed: {
        currentAccount() {
            return this.$store.state.currentAccount
        }
    },
    methods: {
        async deleteInvoice(client_number) {
            if (client_number) {
                try {
                    const response = await axios.delete(
                        this.apiUrl + '/api/user/accounts',
                        {
                            params: {
                                client_number
                            },
                            headers: {
                                'Content-Type': 'application/json',
                                'Authorization': 'Bearer ' + this.token
                            }
                        }
                    )
                    this.$emit('showModal')
                    // this.$store.commit('deleteAccount', client_number)
                    window.location.href = '/'
                    this.$store.getters.currentAccount
                } catch (e) {
                    console.log(e.response)
                }
            }
        }
    }
}
</script>