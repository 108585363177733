<template>
    <div v-if="statements && !noStatement">
        <div v-for="(item, i) in statements" class="history-item">
            <user-meters-table
                v-if="item"
                :fullAddress="item.fullAddress"
                :item = 'item'
                :serialNumber = 'item.serialNumber'
            />
        </div>
    </div>
    <div v-else-if="!noStatement">Загрузка...</div>
    <div v-if="!statements.length">Нет истории показаний</div>
</template>

<script>
export default {
    name: 'user-meters-data',
    data() {
        return {
            show: false
        }
    },
    mounted() {
        this.$store.state.statements = {}
        this.$store.state.payments = []
        this.$store.state.currentStatement = false
    },
    computed: {
        statements() {
            this.$store.commit('getPremisesMetersStatements')

            return this.$store.getters.currentStatemet
        },
        noStatement() {
            return this.$store.state.noStatement
        },
        loadStatements() {
            return this.$store.state.loadStatements
        }
    },
}
</script>