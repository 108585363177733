<template>
    <transition name="fade">
        <div v-if="show || showDef" class="overlay">
            <div class="modal-wrap">
                <div
                    class="modal-win"
                    :class="modalSuccess ? modalSuccess : modalClass"
                    :data-client-number="dataClientNumber"
                >
                    <a
                        href="#"
                        class="modal-close"
                        @click.prevent="showModal"
                        ref="modalClose">

                        <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11 8.5L17 2.5C17.5523 1.94772 17.5523 1.05228 17 0.5C16.4477 -0.0522847 15.5523 -0.0522853 15 0.5L9 6.5L3 0.5C2.44772 -0.0522845 1.55229 -0.0522848 1 0.5C0.447716 1.05229 0.447715 1.94771 1 2.5L7 8.5L0.999999 14.5C0.447715 15.0523 0.447715 15.9477 1 16.5C1.55228 17.0523 2.44771 17.0523 3 16.5L9 10.5L15 16.5C15.5523 17.0523 16.4477 17.0523 17 16.5C17.5523 15.9477 17.5523 15.0523 17 14.5L11 8.5Z"></path>
                        </svg>
                    </a>
                    <modal-forgot-password
                        v-if="type == 'forgotPassword'" @onSuccess="onSuccess" />

                    <modal-edit-phone
                        v-else-if="type == 'editPhone'" @showModal="showModal"/>

                    <modal-edit-phone-personal-data
                        v-else-if="type == 'editPersonalData'" @showModal="showModal"/>

                    <modal-edit-email
                        v-else-if="type == 'editEmail'" @showModal="showModal"/>

                    <modal-edit-snils
                        v-else-if="type == 'editSnils'" @showModal="showModal"/>

                    <modal-edit-email-bill
                        :dataEmailValue="dataEmailValue"
                        :inputName="inputName"
                        @setEmail="setEmail"
                        v-else-if="type == 'editEmailBill'" @showModal="showModal"/>

                    <modal-edit-address-bill
                        :dataAddressValue="dataAddressValue"
                        v-else-if="type == 'editAddressBill'" @showModal="showModal"/>

                    <modal-add-invoice
                        v-else-if="type == 'addInvoice'" @showModal="showModal"/>

                    <modal-delete-invoice
                        v-else-if="type == 'deleteInvoice'" @showModal="showModal"/>

                    <modal-auto-pay
                        v-else-if="type == 'autoPay'" :bindingsCard="bindingsCard" :amount="amount"  @showModal="showModal"/>

                    <modal-change-password
                        v-else-if="type == 'changePass'" @showModal="showModal"/>

                    <modal-feedback
                        v-else-if="type == 'modalFeedback'" @showModal="showModal"/>

                    <modal-notice
                        v-else-if="type == 'modalNotice'" @showModal="showModal"/>

                    <modal-success
                        v-else-if="type == 'success'" @showModal="showModal"/>

                    <modal-additional-email
                        v-else-if="type == 'additionEmail'" @showModal="showModal"/>

                    <modal-additional-phone
                        v-else-if="type == 'additionPhone'" @showModal="showModal"/>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
export default {
    name: 'modal-win',
    props: ['type', 'modalClass', 'dataClientNumber', 'dataEmailValue', 'dataAddressValue', 'showDef', 'bindingsCard', 'amount', 'inputName'],
    data() {
        return {
            show: false,
            modalSuccess: ''
        }
    },
    methods: {
        showModal() {
            if (this.showDef)
                this.$store.state.showPayWin = false

            this.show = !this.show
        },
        closeModal() {
          if (this.type == 'addInvoice')
            this.show = false
        },
        setEmail(e) {
            this.$emit('setEmail', e)
        },
        onSuccess() {
            this.modalSuccess = 'modal-success'
        }
    }
}
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
    transition: opacity .3s ease;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}
</style>