<template>
    <a href="#"
        @click.prevent="show = !show"
        class="history-month"
        :class="{active: show}"
    >
        {{formatDate(item.readDateTime, true)}}
    </a>
    <transition name="slidedown">
        <div v-show="show" class="history-item__wrap">
            <div class="history-table">
                <div class="history-address">{{fullAddress}}</div>
                <table>
                    <thead>
                        <tr>
                            <th>Счетчик</th>
                            <th>Метод</th>
                            <th>Показания переданы</th>
                            <th>Учтены в счете</th>
                            <th>Снял показания</th>
                            <th>Показания</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                № {{serialNumber}}
                            </td>
                            <td>По показаниям</td>
                            <td>
                                {{formatDate(item.readDateTime)}}
                            </td>
                            <td>
                                {{formatDate(item.readDateTime, true)}}
                            </td>
                            <td>
                                {{item.meterSourceDescr}}
                            </td>
                            <td v-if="item.registerRead.registerReading">
                                <p>
                                    {{item.registerRead.readSequence == 10 ? 'День' : 'Ночь'}}
                                    <span>{{Number(item.registerRead.registerReading)}} кВт*ч</span>
                                </p>
                            </td>
                            <td v-else>
                                <p v-for="val in item.registerRead">
                                    {{val.readSequence == 10 ? 'Пик' : val.readSequence == 20 ? 'Полупик' : 'Ночь'}}
                                    <span>{{Number(val.registerReading)}} кВт*ч</span>
                                </p>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div class="history-table__mob">
                    <table>
                        <tbody>
                            <tr>
                                <td>Счетчик</td>
                                <td>№ {{serialNumber}}</td>
                            </tr>
                            <tr>
                                <td>Метод</td>
                                <td>По показаниям</td>
                            </tr>
                            <tr>
                                <td>Показания переданы</td>
                                <td>{{formatDate(item.readDateTime)}}</td>
                            </tr>
                            <tr>
                                <td>Учтены в счете</td>
                                <td>{{formatDate(item.readDateTime, true)}}</td>
                            </tr>
                            <tr>
                                <td>Снял показания</td>
                                <td>{{item.meterSourceDescr}}</td>
                            </tr>
                            <tr>
                                <td>Показания</td>
                                <td>{{Number(item.registerRead.registerReading)}} кВт*ч</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import moment from 'moment'
moment.locale('ru')

export default {
    name: 'user-meters-table',
    props: [
        'item',
        'serialNumber',
        'fullAddress',
    ],
    data() {
        return {
            show: false
        }
    },
    methods: {
        formatDate(dateStr, noDay) {
            if (dateStr) {
                let indexOf = dateStr.lastIndexOf('-')

                if (!noDay)
                    return moment(dateStr.slice(0, indexOf)).format('D MMMM Y')
                else
                    return moment(dateStr.slice(0, indexOf)).format('MMMM Y')
            } else 
                return false
        }
    }
}
</script>

<style scoped>
.slidedown-enter-active {
  transition: max-height 0.5s ease;
}

.slidedown-leave-active{
    transition: max-height 0.1s ease;
}

.slidedown-enter-to,
.slidedown-leave-from {
  overflow: hidden;
  max-height: 1000px;
}

.slidedown-enter-from,
.slidedown-leave-to {
  overflow: hidden;
  max-height: 0;
}
</style>