<template>
    <modal-win
        type="editPersonalData"
        ref="modal"
    />
    <div class="backward-button__wrap">
        <a href="#" @click.prevent="backPage()" class="backward-button large">
            <i>
                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11 6.8C11.4418 6.8 11.8 6.44183 11.8 6C11.8 5.55817 11.4418 5.2 11 5.2V6.8ZM0.434315 5.43431C0.121895 5.74673 0.121895 6.25327 0.434315 6.56569L5.52548 11.6569C5.8379 11.9693 6.34443 11.9693 6.65685 11.6569C6.96927 11.3444 6.96927 10.8379 6.65685 10.5255L2.13137 6L6.65685 1.47452C6.96927 1.1621 6.96927 0.655565 6.65685 0.343146C6.34443 0.0307264 5.8379 0.0307264 5.52548 0.343146L0.434315 5.43431ZM11 5.2L1 5.2V6.8L11 6.8V5.2Z"></path>
                </svg>
            </i>
            Профиль пользователя
        </a>
    </div>
    <header class="header">
        <div class="wrap user-profile flex">
            <div class="header__right">
                <div class="user-info">
                    <div class="user-photo"></div>
                    <div class="user-name">{{dataUser.name ? dataUser.surname + ' ' + dataUser.name + ' ' + dataUser.second_name : 'Без имени'}}</div>
                    <div class="user-birthday">Дата рождения: {{dataUser.birthday ? dataUser.birthday : 'Не указана'}}</div>
                </div>
            </div>
            <div class="header__left">
                <!-- userbar -->
                <user-bar />
                <a href="#" @click.prevent="showModalEditPersonalData" class="edit-profile"></a>
            </div>
        </div>
    </header>
</template>

<script>
import moment from 'moment'

export default {
    name: 'header-block-profile',
    data() {
        return {
            
        }
    },
    computed: {
        dataUser() {
            let dataUser = this.$store.state.dataUser
            if (dataUser.name == null)
                dataUser.name = ''
            if (dataUser.second_name == null)
                dataUser.second_name = ''
            if (dataUser.surname == null)
                dataUser.surname = ''

            dataUser.birthday = moment(dataUser.birthday).format('DD.MM.YYYY')

            return dataUser
        },
    },
    methods: {
        showModalEditPersonalData() {
            this.$refs.modal.showModal()
            this.modalType = 'editPersonalData'
        },
        backPage() {
            this.$router.go(-1)
        }
    }
}
</script>

<style scoped>
.backward-button svg{
    fill: #535353;
}
.backward-button i{
    transition: background ease .2s;
}
.backward-button:hover i{
    background: #d3d3d3;
}
</style>