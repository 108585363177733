<template>
    <div class="input-wrap">
        <label class="input-label">{{ label }}<span v-if="required" class="required">*</span></label>
        <div class="password-wrap">
            <input
                class="input-text"
                autocomplete="off"
                :type="!showPassword ? 'password' : 'text'"
                :class="errorText ? 'error' : ''"
                :placeholder="placeholder"
                :name="name"
                :disabled="inputDisabled"
                v-model="value"
                @accept="onAccept"
                @keyup="onComplete"
            >
            <a href="#"
                class="password-wrap__show"
                :class="showPassword ? 'active' : ''"
                @click.prevent="!showPassword ? showPassword = true : showPassword = false"
            ></a>
        </div>
        <span v-if="errorText" class="input-error">{{errorText}}</span>
    </div>
</template>

<script>
export default {
    name: 'input-password',
    props: [
        'label',
        'required',
        'errorText',
        'placeholder',
        'name'
    ],
    data() {
        return {
            error: false,
            showPassword: false,
            value: '',
            inputDisabled: false,
            newErrorText: ''
        }
    },
    methods: {
        onAccept(e) {
            const maskRef = e.detail
            this.value = maskRef.value
        },
        onComplete(e) {
            this.$emit('onComplete', this)
        },
        showError() {
            this.error = true
        },
        hideError() {
            this.error = false
        },
        setDisabled(status) {
            this.inputDisabled = status
        },
        changeErrorText(text) {
            this.newErrorText = text
        }
    }
}
</script>