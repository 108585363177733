<template>
    <div class="footer">
        <p>Многоканальная линия</p>
        <a href="tel:88002200009">8-800-220-00-09</a>
        <p>Звонок бесплатный</p>
        <div>398024, г.Липецк, пр. Победы, 87а</div>
        <div>Информация на сайте не является публичной офертой</div>
    </div>
</template>

<script>
export default {
    name: 'footer-block'
}
</script>