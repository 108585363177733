<template>
    <div class="container flex">
        <side-bar />
        <div class="content">
            <header-block-small text="Оплата" />
            <div class="content-wrap">
                <div class="payment-wrap">
                    <div class="payment-columns flex">
                        <label class="input-label">Итого к оплате:</label>
                        <div class="payment-left">
                            <div v-if="currentAccount" class="input-wrap">
                                <input
                                    class="input-text"
                                    type="text"
                                    v-imask="rubl"
                                    :value="currentAccount.saldo.balance.currentBalance > 0 ? currentAccount.saldo.balance.currentBalance : this.defaultSumm"
                                    @keyup="keyup"
                                >
                            </div>
                        </div>
                        <div v-if="currentAccount.customer_class != 'QL'" class="payment-right">
                            <p>Для вашего удобства вы можете сделать авансовый платеж<br> на один или несколько месяцев вперед.</p>
                            <a v-if="currentAccount.bindings && !currentAccount.saldo.autopay.status && bindingsCardId != 'new'" href="#" @click.prevent="showModalAutoPay" class="button reset orange-color">Подключить автоплатеж</a>
                            <a v-if="currentAccount.bindings && currentAccount.saldo.autopay.status" href="#" @click.prevent="disableAutoPay" class="button reset orange-color">Отключить автоплатеж</a>
                        </div>
                    </div>
                    <div class="payment-method payment-columns flex">
                        <div class="payment-left">
                            <div class="title">Способ оплаты</div>
                            <div
                                v-if="currentAccount.bindings && currentAccount.customer_class != 'QL'"
                                v-for="(card, key) in currentAccount.bindings"
                                class="payment-card flex"
                            >
                                <label class="radio">
                                    <input
                                        class="radio-card"
                                        name="type"
                                        type="radio"
                                        :value="card.bindingId"
                                        :checked="currentAccount.customer_class != 'QL' && key == 0"
                                        @change="bindingsCardSelect"
                                    >
                                    <span></span>
                                </label>
                                <div class="existing-card flex">
                                    <img src="/assets/images/card.svg" alt="">
                                    <span>{{card.displayLabel}}</span>
                                    <a @click.prevent="deleteCard" href="#" class="existing-card-del"></a>
                                </div>
                            </div>
                            <div class="payment-card flex">
                                <label class="radio">
                                    <input
                                        name="type"
                                        :checked="currentAccount.customer_class == 'QL' || !currentAccount.bindings"
                                        @change="bindingsCardSelect"
                                        value="new" type="radio">
                                    <span></span>
                                </label>
                                <div class="new-card">
                                    <div class="new-card__title">Новая карта</div>
                                    <label v-if="currentAccount.customer_class != 'QL'" class="checkbox white large">
                                        <input class="save-card-check" @change="saveCard" type="checkbox">
                                        <span>Сохранить карту для дальнейших платежей</span>
                                    </label>
                                    <label v-if="currentAccount.customer_class != 'QL'" class="checkbox white large autopay-check" style="display: none;">
                                        <input class="autopay-input-check" @click="checkAutoPay" type="checkbox">
                                        <span>Подключить автоплатеж</span>
                                    </label>
                                    <div class="new-card__hint">К оплате принимаются платежные карты VISA Inc, MasteCard WorldWide, МИР</div>
                                </div>
                            </div>
                          <div v-if="currentAccount.customer_class != 'QL'" class="payment-card flex">
                            <label class="radio">
                              <input
                                  name="type"
                                  @change="bindingsCardSelect"
                                  value="sbp" type="radio">
                              <span></span>
                            </label>
                            <div class="new-card">
                              <div class="new-card__title">Система быстрых платежей</div>
                            </div>
                          </div>
                        </div>
                        <div class="payment-right">
                            <div class="payment-qr flex">
                                <ul class="list-style">
                                    <li>без комиссии</li>
                                    <li>картой любого банка</li>
                                    <li>безопасность транзакции обеспечивается сервисом Сбербанка</li>
                                    <li>платежные данные и реквизиты хранятся и обрабатываются в системе банка</li>
                                </ul>
                                <img
                                    class="img-qr"
                                    :class="{
                                        hide: currentAccount.customer_class == 'QL'
                                    }"
                                alt="">
                            </div>
                        </div>
                    </div>
                    <div class="payment-buttons">
                        <div>
                            <button
                                v-if="currentAccount"
                                @click.prevent="payAction"
                                class="button"
                            >Оплатить</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <modal-win type="autoPay" ref="modal" :bindingsCard="this.$store.state.bindingsCardId" :amount="modalAmount" modalClass="wide-modal" />
</template>

<script>
import axios from "axios"
import { IMaskDirective } from 'vue-imask'

export default {
    name: 'invoice-page',
    data() {
        return {
            clientType: '',
            userType: [
                'Собственник',
                'Арендатор',
                'Зарегистрированный',
                'Проживает',
                'Другое',
            ],
            imageQr: '',
            rubl: {
                mask: 'num {руб.}',
                lazy: false,
                blocks: {
                    num: {
                        mask: Number,
                    }
                }
            },
            accountSumm: false,
            apiUrl: this.$store.state.apiUrl,
            token: this.$store.state.token,
            bindingsCardId: '',
            saveNewCard: false,
            onAccept (e) {
                let val = Math.abs(e.target.value.replace(' руб.', '').replace(',', '.'))
                console.log('onAccept: ' + val)
                this.paymentSumm = val
            },
            defaultSumm: 100,
            paymentSumm: 100,
            showCheckAutoPay: true,
            checkInputAutoPay: true,
            modalAmount: false,
        }
    },
    computed: {
        currentAccount() {
            let currentAccount = this.$store.state.currentAccount

            if (currentAccount) {
                this.paymentSumm = currentAccount.saldo.balance.currentBalance > 0 ? currentAccount.saldo.balance.currentBalance : this.defaultSumm
                this.getQR(currentAccount.account_id, (Math.abs(currentAccount.saldo.balance.currentBalance) * 100).toFixed(0))

                if (currentAccount.bindings) {
                    this.$store.state.bindingsCardId = currentAccount.bindings[0].bindingId
                } else {
                    this.$store.state.bindingsCardId = 'new'
                }
            }

            return currentAccount
        },
    },
    mounted() {
        if (!this.$store.state.authStatus)
            this.$router.push('/auth/')
    },
    directives: {
        imask: IMaskDirective
    },
    methods: {
        saveCard(e) {
            let autopayCheck = document.getElementsByClassName('autopay-check')[0]

            this.saveNewCard = e.target.checked
            this.checkInputAutoPay = e.target.checked

            if (e.target.checked)
                autopayCheck.style.display = 'block'
            else
                autopayCheck.style.display = 'none'
        },
        checkAutoPay(e) {
            this.checkInputAutoPay = e.target.checked
        },
        bindingsCardSelect(e) {
            this.$store.state.bindingsCardId = e.target.value
        },
        keyup(e) {
            let val = Math.abs(e.target.value.replace(' руб.', '').replace(',', '.'))
            this.getQR(this.currentAccount.account_id, (val * 100).toFixed(0))
            this.paymentSumm = val
        },
        changeSumm() {
            this.getQR(this.currentAccount.account_id, this.paymentSumm)
        },
        setInputDisabled(status) {
            if (this.$refs.submitButton)
                this.$refs.submitButton.setDisabled(status)
        },
        showModalAutoPay() {
            this.$refs.modal.showModal()
        },
        async getQR(account_id, amount) {
            try {
                const response = await axios.get(
                    this.apiUrl + '/api/qr',
                    {
                        params: {
                            account_id,
                            amount,
                            size: 210
                        },
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + this.token
                        }
                    }
                )
                // this.imageQr = response.data
                let img = document.getElementsByClassName('img-qr')[0]
                if (img) {
                    img.src = response.data
                }
            } catch (e) {
                console.log(e);
            }
        },
        async payAction() {
          let amount = this.paymentSumm
          let autopayInputCheck = document.getElementsByClassName('autopay-input-check')[0]
          let saveCardCheck = document.getElementsByClassName('save-card-check')[0]

          if (this.currentAccount.customer_class != 'QL' && autopayInputCheck.checked && saveCardCheck.checked && this.$store.state.bindingsCardId == 'new') {
            // console.log(autopayInputCheck.checked);
            this.modalAmount = amount
            this.showModalAutoPay()
          } else {
            try {
              let data = {
                account_id: this.currentAccount.account_id,
                amount: (amount * 100).toFixed(0),
                bind: this.saveNewCard
              }

              if (this.$store.state.bindingsCardId != 'new' && this.$store.state.bindingsCardId != 'sbp' && this.$store.state.bindingsCardId)
                data.binding_id = this.$store.state.bindingsCardId

              const response = await axios.post(
                  this.apiUrl + (this.$store.state.bindingsCardId != 'sbp' ? '/api/pay' : '/api/pay_sbp'),
                  data,
                  {
                    headers: {
                      'Content-Type': 'application/json',
                      'Authorization': 'Bearer ' + this.token
                    }
                  }
              )

              window.location.href = response.data.paymentLink
            } catch (e) {
              console.log(e)
              console.log(e.response)
            }
          }
        },
        async deleteCard() {
            let radioButton =  Array.prototype.slice.call(document.getElementsByClassName('radio-card'))
            let bindingId

            radioButton.forEach(val => {
                if (val.checked)
                    bindingId = val.value
            })
            
            if (this.currentAccount) {
                try {
                    const response = await axios.delete(
                        this.apiUrl + '/api/pay/bindings',
                        {
                            params: {
                                account_id: this.currentAccount.account_id,
                                binding_id: bindingId
                            },
                            headers: {
                                'Content-Type': 'application/json',
                                'Authorization': 'Bearer ' + this.token
                            }
                        }
                    )
                    this.$store.state.accounts = []
                    this.$store.commit('getAccounts')
                    this.$store.getters.currentAccount(this.currentAccount.account_id)
                } catch (e) {
                    console.log(e.response)
                }
            }
        },
        async disableAutoPay() {
            try {
                this.checkboxError = false
                const response = await axios.delete(
                    this.apiUrl + '/api/pay/autopay',
                    {
                        params: {
                            account_id: this.currentAccount.account_id
                        },
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + this.token
                        }
                    }
                )
                this.$store.state.accounts = []
                this.$store.commit('getAccounts')
                this.$store.getters.currentAccount(this.currentAccount.account_id)
                // console.log(response)
            } catch(e) {
                if (e.response)
                    this.error = e.response.data.message

                console.log(e);
            }
        }
    },
}
</script>