import { createStore } from "vuex"
import axios from "axios"
import moment from 'moment'
moment.locale('ru')

export default createStore({
    state: {
        apiUrl:                     'https://api.noviten.ru',
        authStatus:                 false,
        authErrors:                 false,
        token:                      '',
        dataUser:                   {},
        regVerify:                  false,
        regErrors:                  false,
        verifyErrors:               false,
        verifyOk:                   false,
        accounts:                   [],
        currentAccount:             false,
        selectAccount:              false,
        payments:                   [],
        premises:                   false,
        meters:                     {},
        currentMeter:               false,
        statements:                 {},
        meterConfigurationId:       {},
        noStatement:                false,
        loadStatements:             false,
        currentStatement:           false,
        notice:                     false,
        docs:                       false,
        bills:                      {},
        currentBill:                false,
        feedback:                   false,
        premisesMetersStatements:   false,
        bindingsCard:               false,
        loadDataStatus:             false,
        status401:                  false,
        showPayWin:                 false,
        payStatus:                  false,
        feedbackCateg:              false,
        feedbackSections:           false,
        bindingsCardId:             false,
    },
    getters: {
        currentAccount: state => id => {
            for (let key in state.accounts) {
                if (key == 0 && !id) {
                    state.currentAccount = state.accounts[key]
                    return state.currentAccount
                }
                else if (state.accounts[key].client_number == id) {
                    state.currentAccount = state.accounts[key]
                    return state.currentAccount
                }
            }
        },
        currentStatemet: state => {
            state.currentStatement = state.statements[state.currentAccount.account_id]
            let meterReads = []
            if (state.currentStatement) {
                state.currentStatement.forEach(item => {
                    if (item.meterReads) {
                        item.meterReads.forEach(statement => {
                            statement.fullAddress = item.fullAddress
                            statement.serialNumber = item.serialNumber
                            statement.dateTime = moment(statement.readDateTime, 'YYYY-MM-DD-H.m.s').unix()
                            
                            meterReads.push(statement)
                        })
                    }
                })
            }

            meterReads.sort((a, b) => {
                return b.dateTime - a.dateTime
            })

            return meterReads
        },
        currentMeter: state => {
            for (let key in state.meters) {
                if (state.currentAccount.account_id == key) {
                    state.currentMeter = state.meters[key]

                    for (let key2 in state.currentMeter) {
                        state.currentMeter[key2].spList.forEach((val, i) => {
                            if (!Array.isArray(val.mtrCfgDetails.registers)) {
                                state.currentMeter[key2].spList[i].lastMeterRead.tariff = val.mtrCfgDetails.registers.tariff
                            } else {
                                val.mtrCfgDetails.registers.forEach((val2, i2) => {
                                    state.currentMeter[key2].spList[i].lastMeterRead.registerRead[i2].tariff = val2.tariff
                                })
                            }
                        })
                    }
                }
            }

            if (state.currentMeter)
                state.currentMeter.forEach(meter => {
                    if (meter.spList)
                        meter.spList.forEach(sp => {
                            if (sp.lastMeterRead.registerRead && Array.isArray(sp.lastMeterRead.registerRead))
                                sp.lastMeterRead.registerRead.forEach(regRead => {
                                    sp.mtrCfgDetails.registers.forEach(register => {
                                        if (regRead.registerId == register.registerId)
                                            regRead.timeOfUse = register.timeOfUse
                                    })
                                })
                        })
                })

            return state.currentMeter
        },
        currentBill: state => {
            if (state.currentAccount.account_id) {
                for (let key in state.bills) {
                    if (state.currentAccount.account_id == key) {
                        state.currentBill = state.bills[key][0]
                    }
                }
            }

            return state.currentBill
        },
        feedback: state => id => {
            let feedback
            if (state.feedback) {
                feedback = state.feedback.filter(item => item.id == id)
                feedback = feedback[0]
            }

            return feedback
        },
        getFeedbackTheme: state => name => {
            let themes = []
            let sections = []

            if (state.feedbackCateg) {
                state.feedbackCateg.forEach(val => {
                    themes.push(val.name)
                })

                if (!name)
                    state.feedbackCateg.forEach((val, i) => {
                        if (i == 0)
                            val.themes.forEach(name => {
                                sections.push(name.name)
                            })
                    })
                else
                    state.feedbackCateg.forEach((val, i) => {
                        if (val.name == name)
                            val.themes.forEach(name => {
                                sections.push(name.name)
                            })
                    })
            }
            
            state.feedbackSections = sections

            return themes
        },
    },
    mutations: {
        // https://github.com/axios/axios#handling-errors
        async login(state, data) {
            try {
                const response = await axios.post(
                    state.apiUrl + '/api/auth/login',
                    data,
                    {
                        headers: {
                            'Content-Type': 'application/json',
                        }
                    }
                )
                state.authErrors = false
                state.authStatus = true

                state.token         = response.data.token
                localStorage.token  = response.data.token

                state.dataUser      = response.data.user
                localStorage.user   = JSON.stringify(response.data.user)

                if (data.reload)
                    window.location.reload()
            } catch (e) {
                console.log(e);
                state.authErrors = e.response.data.errors ? e.response.data.errors : e.response.data
                console.log(e.response.data)
            }
        },
        async register(state, data) {
            try {
                const response = await axios.post(
                    state.apiUrl + '/api/auth/register',
                    data,
                    {
                        headers: {
                            'Content-Type': 'application/json',
                        }
                    }
                )
                state.regErrors = false
                state.regVerify = true
            } catch (e) {
                console.log(e.response.data.message)
                state.regErrors = {}
                if (e.response.data.errors)
                    state.regErrors = e.response.data.errors
                else if (e.response.data.message)
                    state.regErrors.message = e.response.data.message
            }
        },
        async verify(state, data) {
            try {
                const response = await axios.post(
                    state.apiUrl + '/api/auth/verify',
                    data,
                    {
                        headers: {
                            'Content-Type': 'application/json',
                        }
                    }
                )
                state.verifyErrors  = false
                state.verifyOk      = true
            } catch (e) {
                state.verifyErrors = e.response.data.errors ? e.response.data.errors : e.response.data
                console.log(e.response.data);
            }
        },
        async getUserInfo(state) {
            try {
                const response = await axios.get(
                    state.apiUrl + '/api/user',
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + state.token
                        }
                    }
                )
                state.dataUser = response.data
                !state.dataUser.name ? state.dataUser.name = 'Без имени' : state.dataUser.name = state.dataUser.name
                localStorage.user = JSON.stringify(response.data)
            } catch (e) {
                console.log(e.response)
                if (e.response.status == 401)
                    state.status401 = 401
            }
        },
        async getAccounts(state, reload) {
            if (reload) {
                state.accounts = []
                state.currentAccount = false
            }

            try {
                state.loadDataStatus = true
                const accountsRes = await axios.get(
                    state.apiUrl + '/api/user/accounts',
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + state.token
                        }
                    }
                )
                
                if (!accountsRes.data.accounts.length)
                    state.loadDataStatus = false

                // добавляем к аккаунтам saldo
                accountsRes.data.accounts.forEach(async (val, i) => {
                    if (val.account_id) {
                        try {
                            const bindingsRes = await axios.get(
                                state.apiUrl + '/api/pay/bindings',
                                {
                                    params: {account_id: val.account_id},
                                    headers: {
                                        'Content-Type': 'application/json',
                                        'Authorization': 'Bearer ' + state.token
                                    }
                                }
                            )
                            
                            // console.log(bindingsRes.data);
                            accountsRes.data.accounts[i].bindings = bindingsRes.data.bindings.length ? bindingsRes.data.bindings : false
                        } catch (e) {
                            console.log(e);
                            if (e.response && e.response.status == 401)
                                state.status401 = 401
                        }
                        // 
                        try {
                            const saldoRes = await axios.get(
                                state.apiUrl + '/api/saldo',
                                {
                                    params: {account_id: val.account_id},
                                    headers: {
                                        'Content-Type': 'application/json',
                                        'Authorization': 'Bearer ' + state.token
                                    }
                                }
                            )
                            accountsRes.data.accounts[i].saldo = saldoRes.data
                        } catch (e) {
                            console.log(e.response)
                            if (e.response.status == 401)
                                state.status401 = 401
                        }

                        try {
                            const addressRes = await axios.get(
                                state.apiUrl + '/api/address',
                                {
                                    params: {person_id: val.person_id},
                                    headers: {
                                        'Content-Type': 'application/json',
                                        'Authorization': 'Bearer ' + state.token
                                    }
                                }
                            )
                            accountsRes.data.accounts[i].person = addressRes.data

                            let existsAccount = state.accounts.filter(account => account.account_id == accountsRes.data.accounts[i].account_id)

                            if (!existsAccount.length)
                                state.accounts.push(accountsRes.data.accounts[i])

                            state.loadDataStatus = false

                        } catch (e) {
                            state.loadDataStatus = false
                            console.log(e)
                            console.log(e.response)
                            if (e.response.status == 401)
                                state.status401 = 401
                        }
                    }
                })
            } catch (e) {
                console.log(e.response)
                if (e.response.status == 401)
                    state.status401 = 401
            }
        },
        async getPayments(state, {accountId, date}) {
            if (accountId) {
                let payment = state.payments.find(item => item.accountId == accountId)
                if (!payment) {
                    state.loadDataStatus = true
                    try {
                        const response = await axios.get(
                            state.apiUrl + '/api/payments',
                            {
                                params: {
                                    account_id: accountId,
                                    // date_from: '2020-08-31',
                                    // date_to: '2020-10-31',
                                    date_from: date.startDate,
                                    date_to: date.dateNow
                                },
                                headers: {
                                    'Content-Type': 'application/json',
                                    'Authorization': 'Bearer ' + state.token
                                }
                            }
                        )
                        state.payments.push(response.data)
                        state.loadDataStatus = false
                    } catch (e) {
                        state.loadDataStatus = false
                        state.payments = 'Нет истории оплат'
                        console.log(e.response)
                        if (e.response.status == 401)
                            state.status401 = 401
                    }
                }
            }
        },
        async getPremisesMetersStatements(state) {
            if (!state.statements.hasOwnProperty(state.currentAccount.account_id)) {
                if (state.currentAccount.account_id) {
                    state.loadDataStatus = true
                    try {
                        const premises = await axios.get(
                            state.apiUrl + '/api/premises',
                            {
                                params: {
                                    account_id: state.currentAccount.account_id,
                                },
                                headers: {
                                    'Content-Type': 'application/json',
                                    'Authorization': 'Bearer ' + state.token
                                }
                            }
                        )
                        
                        if (!Array.isArray(premises.data.premises))
                            premises.data.premises = [premises.data.premises]

                        state.meters[state.currentAccount.account_id] = []
                        
                        premises.data.premises.forEach(async (val, i) => {
                            state.noStatement = false
                            if (val) {
                                const meters = await axios.get(
                                    state.apiUrl + '/api/meters',
                                    {
                                        params: {
                                            account_id: state.currentAccount.account_id,
                                            premise_id: val.premiseId
                                        },
                                        headers: {
                                            'Content-Type': 'application/json',
                                            'Authorization': 'Bearer ' + state.token
                                        }
                                    }
                                )

                                meters.data.fullAddress = val.address.fullAddress
                                meters.data.premiseId = val.premiseId

                                if (meters.data.spList) {
                                    if (!Array.isArray(meters.data.spList))
                                        meters.data.spList = [meters.data.spList]
                                    else if (!meters.data.spList)
                                        meters.data.spList = []
                                    
                                    if (state.meters[state.currentAccount.account_id])
                                        state.meters[state.currentAccount.account_id].push(meters.data)

                                    let fullAddress = meters.data.fullAddress

                                    state.statements[state.currentAccount.account_id] = []
                                    
                                    let dateFrom = moment()
                                    let dateTo = moment().format('YYYY-MM-DD')
                                    dateFrom = dateFrom.add(-6, 'months').format('YYYY-MM-DD')

                                    if (meters.data.spList) {
                                        meters.data.spList.forEach(async val => {
                                            if(!Array.isArray(state.meterConfigurationId[state.currentAccount.account_id]))
                                                state.meterConfigurationId[state.currentAccount.account_id] = []
                                            
                                            state.meterConfigurationId[state.currentAccount.account_id].push(val.mtrCfgDetails.meterConfigurationId)
                                            
                                            const statements = await axios.get(
                                                state.apiUrl + '/api/statements',
                                                {
                                                    params: {
                                                        meter_config_id: val.mtrCfgDetails.meterConfigurationId,
                                                        date_from: dateFrom,
                                                        date_to: dateTo,
                                                    },
                                                    headers: {
                                                        'Content-Type': 'application/json',
                                                        'Authorization': 'Bearer ' + state.token
                                                    }
                                                }
                                            )
                                            
                                            statements.data.serialNumber = val.mtrDetails.serialNumber
                                            statements.data.fullAddress = fullAddress
                                            state.statements[state.currentAccount.account_id].push(statements.data)
                                        })
                                        // console.log(state.meterConfigurationId);
                                    }
                                } else {
                                    // console.log(meters.data);
                                }
                            } else {
                                state.noStatement = true
                            }
                        })
                        state.loadDataStatus = false
                    } catch (e) {
                        state.loadDataStatus = false
                        if (e.response.status == 401)
                            state.status401 = 401
                    }
                }
            }
        },
        async getStatements(state) {
            let dateFrom = moment()
            let dateTo = moment().format('YYYY-MM-DD')
            dateFrom = dateFrom.add(-6, 'months').format('YYYY-MM-DD')

            if (Array.isArray(state.meterConfigurationId[state.currentAccount.account_id])) {
                const statements = await axios.get(
                    state.apiUrl + '/api/statements',
                    {
                        params: {
                            meter_config_id: state.meterConfigurationId[state.currentAccount.account_id].join(','),
                            date_from: dateFrom,
                            date_to: dateTo,
                        },
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + state.token
                        }
                    }
                )
                state.statements[state.currentAccount.account_id] = statements.data
            }
        },
        async getNotice(state) {
            try {
                const response = await axios.get(
                    state.apiUrl + '/api/notifications',
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + state.token
                        }
                    }
                )
                if (response.data) {
                    for (let key in response.data) {
                        let date = moment(response.data[key].created_at)
                        response.data[key].created_at = date.format('DD MMMM YYYY')
                        response.data[key].created_at_time = date.format('H:mm')
                    }

                    state.notice = response.data
                }
            } catch (e) {
                console.log(e);
                if (e.response.status == 401)
                    state.status401 = 401
            }
        },
        async getBills(state, account_id) {
            if (account_id) {
                if (!state.bills.hasOwnProperty(account_id)) {
                    state.loadDataStatus = true
                    state.bills[account_id] = []
                    try {
                        const response = await axios.get(
                            state.apiUrl + '/api/billsUpd',
                            {
                                params: {
                                    account_id: account_id,
                                    // date: '2020-10-31',
                                    date: moment().add(-1, 'months').endOf('month').format('YYYY-MM-DD'),
                                    count: 2
                                },
                                headers: {
                                    'Content-Type': 'application/json',
                                    'Authorization': 'Bearer ' + state.token
                                }
                            }
                        )
                        
                        if (!Array.isArray(response.data))
                            response.data = [response.data]

                        response.data.forEach((val, i) => {
                            if (val.rowCount && val.sq)
                                if (!Array.isArray(val.sq.sqs))
                                    response.data[i].sq.sqs = [val.sq.sqs]
                        })

                        response.data.forEach((val, i) => {
                            let date = val.dt ? val.dt : response.data[i].date;
                            response.data[i].date = moment(date).format('MMMM YYYY')
                        })

                        state.bills[account_id].push(response.data)

                        state.loadDataStatus = false
                    } catch (e) {
                        state.loadDataStatus = false
                        console.log(e);
                        if (e.response.status == 401)
                            state.status401 = 401
                    }
                }
            }
        },
        async getBindingsCard(state) {
            try {
                const response = await axios.get(
                    state.apiUrl + '/api/pay/bindings',
                    {
                        params: {account_id: state.currentAccount.account_id},
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + state.token
                        }
                    }
                )
                
                state.bindingsCard = response.data.bindings
            } catch (e) {
                console.log(e);
                if (e.response.status == 401)
                    state.status401 = 401
            }
        },
        async getFeedback(state) {
            try {
                const response = await axios.get(
                    state.apiUrl + '/api/questions',
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + state.token
                        }
                    }
                )
                
                response.data.forEach((val, i) => {
                    let date = val.created_at
                    response.data[i].created_at = moment(date).format('DD MMMM YYYY, HH:mm')
                    response.data[i].created_at_detail = moment(date).format('DD.MM.YYYY')
                    response.data[i].created_at_detail_time = moment(date).format('HH:mm')
                })

                state.feedback = response.data
            } catch (e) {
                console.log(e);
                if (e.response.status == 401)
                    state.status401 = 401
            }
        },
        async getFeedbackCateg(state) {
            try {
                const response = await axios.get(
                    state.apiUrl + '/api/questions/categories',
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + state.token
                        }
                    }
                )

                state.feedbackCateg = response.data
            } catch (e) {
                console.log(e);
                if (e.response.status == 401)
                    state.status401 = 401
            }
        },
        async getDocs(state) {
            try {
                const response = await axios.get(
                    state.apiUrl + '/api/docs',
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + state.token
                        }
                    }
                )

                state.docs = response.data
            } catch(e) {
                console.log(e);
                if (e.response.status == 401)
                    state.status401 = 401
            }
        },
        async updatePayment(state, {orderId}) {
            try {
                const response = await axios.get(
                    state.apiUrl + '/api/pay_callback',
                    {
                        params: {orderId: orderId},
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + state.token
                        }
                    }
                )
                
                try {
                    state.loadDataStatus = true
                    const accountsRes = await axios.get(
                        state.apiUrl + '/api/user/accounts',
                        {
                            headers: {
                                'Content-Type': 'application/json',
                                'Authorization': 'Bearer ' + state.token
                            }
                        }
                    )
                    
                    if (!accountsRes.data.accounts.length)
                        state.loadDataStatus = false

                    // добавляем к аккаунтам saldo
                    accountsRes.data.accounts.forEach(async (val, i) => {
                        if (val.account_id) {
                            try {
                                const bindingsRes = await axios.get(
                                    state.apiUrl + '/api/pay/bindings',
                                    {
                                        params: {account_id: val.account_id},
                                        headers: {
                                            'Content-Type': 'application/json',
                                            'Authorization': 'Bearer ' + state.token
                                        }
                                    }
                                )
                                
                                // console.log(bindingsRes.data);
                                accountsRes.data.accounts[i].bindings = bindingsRes.data.bindings.length ? bindingsRes.data.bindings : false
                            } catch (e) {
                                console.log(e);
                                if (e.response && e.response.status == 401)
                                    state.status401 = 401
                            }
                            // 
                            try {
                                const saldoRes = await axios.get(
                                    state.apiUrl + '/api/saldo',
                                    {
                                        params: {account_id: val.account_id},
                                        headers: {
                                            'Content-Type': 'application/json',
                                            'Authorization': 'Bearer ' + state.token
                                        }
                                    }
                                )
                                accountsRes.data.accounts[i].saldo = saldoRes.data
                            } catch (e) {
                                console.log(e.response)
                                if (e.response.status == 401)
                                    state.status401 = 401
                            }

                            try {
                                const addressRes = await axios.get(
                                    state.apiUrl + '/api/address',
                                    {
                                        params: {person_id: val.person_id},
                                        headers: {
                                            'Content-Type': 'application/json',
                                            'Authorization': 'Bearer ' + state.token
                                        }
                                    }
                                )
                                accountsRes.data.accounts[i].person = addressRes.data

                                let existsAccount = state.accounts.filter(account => account.account_id == accountsRes.data.accounts[i].account_id)

                                if (!existsAccount.length)
                                    state.accounts.push(accountsRes.data.accounts[i])

                                state.loadDataStatus = false

                            } catch (e) {
                                state.loadDataStatus = false
                                console.log(e)
                                console.log(e.response)
                                if (e.response.status == 401)
                                    state.status401 = 401
                            }
                        }
                    })
                } catch (e) {
                    console.log(e.response)
                    if (e.response.status == 401)
                        state.status401 = 401
                }

            } catch(e) {
                console.log(e);
                if (e.response.status == 401)
                    state.status401 = 401
            }
        },
        setAccountNote(state, {note, client_number}) {
            state.accounts.forEach((val, i) => {
                if (client_number == val.client_number)
                    state.accounts[i].note = note
            })
        },
        accountUpdate(state, data) {
            for (let key in state.currentAccount) {
                if (data.hasOwnProperty(key))
                    state.currentAccount[key] = data[key]
            }
        },
        deleteAccount(state, client_number) {
            state.accounts.forEach((account, i) => {
                account.client_number == client_number ? state.accounts.splice(i, 1) : false
            })
        },
        regStep1(state) {
            state.regVerify = false
        },
        resetRegErrors(state) {
            state.regErrors = false
        },
        reloadPageSetValue(state) {
            state.dataUser = JSON.parse(localStorage.user)
            state.token = localStorage.token
            state.authStatus = true
        },
        setSelectAccount(state, id) {
            state.selectAccount = id
        },
        setAccountAddressBill(state, {client_number, bill_address}) {
            state.accounts.forEach((account, i) => {
                account.client_number == client_number ? state.accounts[i].bill_address = bill_address : false
            })
        },
        setAccountEmailBill(state, {client_number, email_bill}) {
            state.accounts.forEach((account, i) => {
                account.client_number == client_number ? state.accounts[i].email_bill = email_bill : false
            })
        },
        logout(state) {
            state.loadDataStatus = false
            state.authStatus    = false
            state.authErrors    = false
            state.token         = ''
            state.dataUser      = {}
            state.regVerify     = false
            state.regErrors     = false
            state.verifyErrors  = false
            state.verifyOk      = false
            localStorage.user   = ''
            localStorage.token  = ''
        },
    }
})