<template>
    <header class="header small">
        <div class="wrap flex">
            <div class="header__right">
                <div class="header__top flex">
                    <div class="header__top-info">
                        <a href="#" @click.prevent="backPage()" class="backward-button">
                            <i>
                                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M11 6.8C11.4418 6.8 11.8 6.44183 11.8 6C11.8 5.55817 11.4418 5.2 11 5.2V6.8ZM0.434315 5.43431C0.121895 5.74673 0.121895 6.25327 0.434315 6.56569L5.52548 11.6569C5.8379 11.9693 6.34443 11.9693 6.65685 11.6569C6.96927 11.3444 6.96927 10.8379 6.65685 10.5255L2.13137 6L6.65685 1.47452C6.96927 1.1621 6.96927 0.655565 6.65685 0.343146C6.34443 0.0307264 5.8379 0.0307264 5.52548 0.343146L0.434315 5.43431ZM11 5.2L1 5.2V6.8L11 6.8V5.2Z"></path>
                                </svg>
                            </i>
                            {{text}}
                        </a>
                        <div v-if="currentAccount" class="header-account-columns flex">
                            <div class="header-account__logo">
                                <img :src="currentAccount.cis_division == 'GESK' ? '/assets/images/noviten.svg' : '/assets/images/logo-lesk-148x52.svg'" alt="">
                            </div>
                            <div v-if="currentAccount.person" class="header-account__data">
                                <p v-if="currentAccount.person.results.adrGroup.fullAddress">{{currentAccount.person.results.adrGroup.fullAddress}}</p>
                                <p v-else>Нет адреса</p>
                                <div class="current-invoice">
                                    <span>Лицевой счет: </span>
                                    <input-text
                                        :inputValue="currentAccount.client_number"
                                        maskVal="invoice"
                                    />
                                </div>
                            </div>
                        </div>
                        <div v-else>Загрузка...</div>
                    </div>
                    <!-- userbar -->
                    <user-bar />
                </div>
            </div>
        </div>
    </header>
</template>

<script>
export default {
    name: 'header-block-small',
    props: ['text'],
    computed: {
        currentAccount() {
            return this.$store.state.currentAccount
        }
    },
    methods: {
        backPage() {
            this.$router.go(-1)
        }
    }
}
</script>