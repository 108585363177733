<template>
    <div class="title">Документы</div>
    <div v-if="docs" class="docs-list">
        <div v-for="doc in docs" class="docs-item">
            <img src="/assets/images/ico-doc.svg" alt="">
            <a :href="doc.url" target="_blank">{{doc.name}}</a>
        </div>
    </div>
    <div v-else>Список документов пуст</div>
</template>

<script>
export default {
    name: 'user-docs',
    computed: {
        docs() {
            let docs = this.$store.state.docs

            if (!docs)
                this.$store.commit('getDocs')

            return docs
        }
    }
}
</script>